import React from "react";
import "./AboutSection.css";
import AboutImage from "../../assets/aboutsection.png";
import ProfileImage from "../../assets/profile-image.svg"; // Assuming you have a profile image

const AboutSection = () => {
  return (
    <>
      <section className="about-section-wrapper">
        <div className="App">
          <div id="about" className="about-section">
            <div className="image-section">
              <img src={AboutImage} alt="about-image" />
            </div>
            <div className="content-section">
              <h2>About</h2>
              <h3>Virtual Intros</h3>
              <h5>Welcome to laigic</h5>
              <p>
              Where innovation meets connection. At Laigic, we are passionate about creating digital solutions that bring people together in meaningful and impactful ways. Our mission is to bridge the gap between social and professional networking, providing a platform where users can build both personal and professional relationships seamlessly. We also specialize in providing top-tier, on-demand technology and AI advisory services that cater to businesses of all sizes, from startups to Fortune 15 companies. Our mission is to empower organizations by delivering strategic AI and digital transformation solutions tailored to their unique needs, goals, and culture.
              </p>
              <div className="testimonial">
                <img src={ProfileImage} alt="Jair Clarke" />
                <div className="testimonial-content">-Jair Clarke</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
