import React from 'react';

const contentData = [
  {
    type: "heading",
    text: "1. Information We Collect"
  },
  {
    type: "text",
    text: "When you use Virtual Intros, we may collect the following types of information, but not limited to:"
  },
  {
    type: "list",
    items: [
      "Personal Identifiable Information (PII): This includes, but is not limited to, your name, email address, phone number, profile information, and any other data that can be used to identify you.",
      "Demographic Data: Such as your age, gender, interests, and preferences.",
      "Usage Data: Information about how you interact with our app, such as the features you use, the pages you visit, and the actions you take.",
      "Device Information: Information about the device you use to access our app, including IP address, operating system, and browser type.",
      "Location Data: With your consent, we may collect and process information about your actual location.",
    ]
  },
  {
    type: "heading",
    text: "2. How We Use Your Information"
  },
  {
    type: "text",
    text: "We use the information we collect to:"
  },
  {
    type: "list",
    items: [
      "Provide and Maintain Our Services: This includes using your PII to manage your account, authenticate users, and deliver the services you request.",
      "Improve Our Services: We may use your data to understand how you use our app, identify trends, and improve user experience.",
      "Communicate With You: We may send you emails or push notifications regarding updates, new features, or other information that might be of interest to you.",
      "Ensure Security and Compliance: To protect our users, we may use your information to detect, prevent, and respond to fraud, security risks, and legal obligations.",
      "Personalize Your Experience: Based on your profile information and preferences, we may tailor the content you see on our app.",
    ]
  },
  {
    type: "heading",
    text: "3. Sharing Your Information"
  },
  {
    type: "text",
    text: "We do not sell your personal information to third parties. However, we may share your information with:"
  },
  {
    type: "list",
    items: [
     "Service Providers: We may share your information with third-party service providers who assist us in operating our app, conducting our business, or serving our users.",
     "Legal Obligations: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).",
     "Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.",
    ]
  },
  {
    type: "heading",
    text: "4. International Data Transfers"
  },
  {
    type: "text",
    text: "Given the global nature of our business, your information may be transferred to and processed in countries outside of your home country. These countries may have data protection laws that are different from the laws of your country. We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy.",
  },
  {
    type: "heading",
    text: "5. Data Retention"
  },
  {
    type: "text",
    text: "We will retain your information for as long as your account is active or as needed to provide you services. We may also retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements."
  },
  {
    type: "heading",
    text: "6. Your Rights"
  },
  {
    type: "text",
    text: "Depending on your location, you may have the following rights regarding your personal data:"
  },
  {
    type: "list",
    items: [
     "Access: You have the right to request access to the personal data we hold about you.",
     "Correction: You have the right to request that we correct any inaccuracies in your personal data.",
     "Deletion: You have the right to request that we delete your personal data.",
    ]
  },
  {
    type: "text",
    text: "If you have any questions or concerns about these Terms and Conditions, please contact us at "
  },
  {
    type: "email",
    text: "contact@laigic.com"
  },
  {
    type: "heading",
    text: "7. Security"
  },
  {
    type: "text",
    text: "We implement a variety of security measures to ensure the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security."
  },
  {
    type: "heading",
    text: "8. Children’s Privacy"
  },
  {
    type: "text",
    text: "Our app is not intended for children under the age of 16. We do not knowingly collect or solicit personal information from children under 16."
  },
  {
    type: "heading",
    text: "9. Changes to This Privacy Policy"
  },
  {
    type: "text",
    text: "We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons."
  },
  {
    type: "heading",
    text: "10. Contact Us"
  },
  {
    type: "text",
    text: "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at  "
  },
  {
    type: "email",
    text: "contact@laigic.com"
  }
  // Add more sections as needed...
];

const PrivacyPolicy = () => {
  return (
    <><div className='termContainer' >
    <div style={{paddingBottom:'30px'}}>
    <div className='termHeading'>Privacy Policy for Virtual Intros</div>
    <div>
      <div className='termBottomTextHeading'>Effective Date: August 1, 2024</div>
      <span className='termBottomText'>Welcome to Virtual Intros! We value your trust and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application Virtual Intros. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat I</span>
    </div>
    </div>
    <div>
      {contentData.map((section, index) => (
        <div key={index}>
          {section.type === 'heading' && <h2 className='termsHeading'>{section.text}</h2>}
          {section.type === 'text' && (
            <p className='termsText'>
              {section.text}
              {contentData[index + 1]?.type === 'email' && (
                <span style={{color:'#F37B2F', fontWeight:'800', lineHeight:'28px',fontSize:'16px'}} className="emailStyle">{contentData[index + 1].text}</span>
              )}
            </p>
          )}
          {section.type === 'list' && (
            <ul>
              {section.items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  </div>
  <hr/>
    </>
  );
}

export default PrivacyPolicy;
