import React from "react";
import "./Header.css";
import logo from "../../assets/logo.svg";
import play_logo from "../../assets/Mask Group 1.svg";
import apple_logo from "../../assets/app_store.svg";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' }); 
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); 
      }
    }, 0); 
  };
  return (
    <header className="header-wrapper">
      <div className="App">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="Virtual Intros Logo" />
          </div>
          <nav className="nav-menu">
            <a onClick={() => handleNavigation('/')}>Home</a>
            <a onClick={() => handleNavigation('/', '#about')}>About us</a>
            <a onClick={() => handleNavigation('/', '#how-it-works')}>How it works</a>
            <a href="#contact">Contact us</a>
            <a 
            href="#" 
            // target="_blank"
            >Services</a>
            <img className="header-play" src={play_logo} />
            <img className="header-apple" src={apple_logo} />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
