// import React from 'react';
// import './ValueSection.css';

// const ValueSection = () => {
//   return (
//     <section className="value-section">
//       <h2>The Value You Get</h2>
//       <div className="values">
//         <div className="value">
//           <h3>01. <br/><span>Individual Users</span></h3>
//           <p>The application provides individual users with the ability to connect and network with industry professionals, access tailored job recommendations, attend and host events, and showcase their professional brand. Users can also manage their preferences, control their visibility, and engage with content relevant to their interests, all while maintaining privacy and enhancing their career growth.</p>
//         </div>
//         <div className="value">
//           <h3>02. Company Users</h3>
//           <p>Manage and invite members to the company profile.</p>
//         </div>
//         {/* Add more values as needed */}
//       </div>
//     </section>
//   );
// };

// export default ValueSection;
import React from "react";
import "./ValueSection.css";

const ValueSection = () => {
  return (
    <div className="value-section-wrapper">
      <div className="App">
        <div className="value-section">
          <h2><b>The Value You Get</b></h2>
          <div className="value-cards">
            <div className="value-card value-card-1">
              <h3>01.</h3>
              <h4>Individual Users</h4>
              <p className="description">
                The Application Provides Individual Users With The Ability To
                Connect And Network With Industry Professionals, Access Tailored
                Job Recommendations, Attend And Host Events, And Showcase Their
                Professional Brand. Users Can Also Manage Their Preferences,
                Control Their Visibility, And Engage With Content Relevant To
                Their Interests, All While Maintaining Privacy And Enhancing
                Their Career Growth.
              </p>
            </div>
            <div className="value-card value-card-2">
              <h3>02.</h3>
              <h4>Company Users</h4>
              <p className="description">
                Company Users Benefit From The Ability To Manage And Invite
                Members To Their Company Profile, Promote Their Brand, Host And
                Manage Events, And Search For Top Talent With Specific Criteria.
                The App Facilitates Seamless Networking, Offering Tools To
                Connect With Potential Candidates And Partners. Companies Can
                Also Track Engagement, Send Targeted Invitations, And Maintain A
                Professional Presence Within The Industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueSection;
