import React from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import ValueSection from "../components/ValueSection/ValueSection";
import AboutSection from "../components/AboutSection/AboutSection";
import DownloadApp from "../components/Download/DownloadApp";
import Footer from "../components/Footer/Footer";
import Working from "../components/Working/Working";
import Layout from "../Layout";

function Home() {
  return (
    <div className="wrapper">
      {/* <Header /> */}
      <HeroSection />
      <HowItWorks />
      <Working />
      <ValueSection />
      <AboutSection />
      {/* <DownloadApp />
      <Footer /> */}
    </div>

  );
}

export default Home;
