import React from 'react';
import './Terms.css';

const contentData = [
  {
    type: "heading",
    text: "1. Acceptance Of Term"
  },
  {
    type: "text",
    text: "By downloading, installing, and/or using Virtual Intros, you agree to be bound by these Terms and Conditions. If you are using the app on behalf of a company or other legal entity, you represent that you have the authority and agree to bind such entity to these terms."
  },
  {
    type: "heading",
    text: "2. User Accounts"
  },
  {
    type: "text",
    text: "You must be at least 16 years old to create an account on Virtual Intros. By creating an account, you represent that you meet this age requirement."
  },
  {
    type: "heading",
    text: "3. User Conduct"
  },
  {
    type: "text",
    text: "You agree to use Virtual Intros in a manner that is lawful, ethical, and in compliance with all applicable laws and regulations. You agree not to:"
  },
  {
    type: "list",
    items: [
      "Use the app for any illegal or unauthorized purpose.",
      "Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.",
      "Harass, abuse, or harm another person or group.",
      "Collect or store app data and personal data about other users without Laigic LLC and user express consent.",
      "Post, share, or otherwise distribute content that is defamatory, obscene, infringing, or otherwise objectionable."
    ]
  },
  {
    type: "heading",
    text: "4. Intellectual Property Right"
  },
  {
    type: "list",
    items: [
      "Ownership: All content, features, concept, and functionality (including but not limited to text, graphics, logos, and software) on Virtual Intros are owned by Laigic, its licensors, or other providers of such material and are protected by copyright, trademark, and other intellectual property laws.",
      "License to Use: We grant you a limited, non-exclusive, non-transferable license to access and use the app for personal and non-commercial purposes. You agree not to reproduce, create similar likeness of the app, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our app without our prior written consent."
    ]
  },
  {
    type: "heading",
    text: "5. User Content"
  },
  {
    type: "list",
    items: [
      "Responsibility: You are solely responsible for the content you post, upload, or otherwise make available through Virtual Intros. By doing so, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content.",
      "Monitoring and Enforcement: We reserve the right to review, screen, and delete any content that violates these Terms and Conditions or is otherwise objectionable. However, we do not undertake any obligation to monitor user content."
    ]
  },
  {
    type: "heading",
    text: "6. Termination"
  },
  {
    type: "text",
    text: "We may terminate or suspend your account and access to the app at our sole discretion, without notice or liability, for any reason, including but not limited to your violation of these Terms and Conditions. Upon termination, your right to use the app will immediately cease."
  },
  {
    type: "heading",
    text: "7. Limitation of Liability"
  },
  {
    type: "text",
    text: "By using Virtual Intros, to the fullest extent possible, you agree that you will not bring any legal action, including but not limited to lawsuits, claims, or proceedings against Laigic, its affiliates, officers, directors, employees, or agents in connection with your use of the app. This includes but is not limited to any claims for damages, losses, or injuries arising out of or related to your use of the app."
  },
  {
    type: "text",
    text: "You acknowledge and agree that this waiver of legal action is a fundamental condition of your use of Virtual Intros. If you do not agree to this waiver, you should discontinue your use of the app immediately."
  },
  {
    type: "text",
    text: "To the fullest extent permitted by applicable law, Laigic, its affiliates, and their respective officers, directors, employees, agents, licensors, and service providers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses resulting from any direct and/or indirect correlation to this app, your access to or use of, or inability to access or use the app."
  },
  {
    type: "heading",
    text: "8. Indemnification"
  },
  {
    type: "text",
    text: "You agree to defend, indemnify, and hold harmless Laigic and its affiliates, and their respective officers, directors, employees, agents, licensors, and service providers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these Terms and Conditions or your use of the app."
  },
  {
    type: "heading",
    text: "9. Governing Law"
  },
  {
    type: "text",
    text: "These Terms and Conditions are governed by and construed in accordance with the laws of the U.S.A., and the location to enforce these laws will be decided by Laigic without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located within the jurisdiction that Laigic LLC chooses to resolve any legal matters arising from these terms."
  },
  {
    type: "heading",
    text: "10. Changes to Terms and Conditions"
  },
  {
    type: "text",
    text: "We reserve the right to update or modify these Terms and Conditions at any time. Your continued use of the app following any such changes constitutes your acceptance of the new terms."
  },
  {
    type: "heading",
    text: "11. Contact Information"
  },
  {
    type: "text",
    text: "If you have any questions or concerns about these Terms and Conditions, please contact us at "
  },
  {
    type: "email",
    text: "contact@laigic.com"
  }
  // Add more sections as needed...
];

const TermAndCondition = () => {
  return (
    <>
    <div className='termContainer'>
      <div style={{paddingBottom:'30px'}}>
      <div className='termHeading'>Terms and Conditions for Virtual Intros</div>
      <div>
        <div className='termBottomTextHeading'>Effective Date: August 1, 2024</div>
        <span className='termBottomText'>Welcome to Virtual Intros! These Terms and Conditions govern your use of our mobile application and services. By accessing or using Virtual Intros, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our app, and we may remove your access to the app.</span>
      </div>
      </div>
      <div>
        {contentData.map((section, index) => (
          <div key={index}>
            {section.type === 'heading' && <h2 className='termsHeading'>{section.text}</h2>}
            {section.type === 'text' && (
              <p className='termsText'>
                {section.text}
                {contentData[index + 1]?.type === 'email' && (
                  <span style={{color:'#F37B2F', fontWeight:'800', lineHeight:'28px',fontSize:'16px'}} className="emailStyle">{contentData[index + 1].text}</span>
                )}
              </p>
            )}
            {section.type === 'list' && (
              <ul>
                {section.items.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
    <hr/>
    </>
  );
}

export default TermAndCondition;
