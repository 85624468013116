import React from "react";
import "./DownloadApp.css";
import DownloadLogo from "../../assets/download-logo.svg";
import appleImage from "../../assets/awesome-apple.svg";
import playImage from "../../assets/Mask Group 1.svg";

const DownloadApp = () => {
  return (
    <section>
      <div className="App">
        <div id="contact" className="download-section">
          <img src={DownloadLogo} alt="Download logo" />
          <h2><b>Download Our App!</b></h2>
          {/* <div className="hero-buttons_1">
            <button className="hero-apple-button_1">
              <img src={appleImage} alt="Apple icon_1" />
              <div className="button-text_1">
                <span>Download on the</span>
                <span style={{fontSize:"18px"}}>App Store</span>
              </div>
            </button>
            <button className="hero-play-button_1">
              <img src={playImage} alt="Play icon_1" />
              <div className="button-text_1">
                <span>Get it on</span>
                <span style={{fontSize:"18px"}}>Play Store</span>
              </div>
            </button>
          </div> */}
          <div className="hero-buttons_1">
          <img src="https://d3nw6jtllu5stf.cloudfront.net/logo/play_buttons.png" alt="Download Buttons" height="75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
