import React from "react";
import "./HeroSection.css";
import phoneImage from "../../assets/phone.svg";
import appleImage from "../../assets/awesome-apple.svg";
import playImage from "../../assets/Mask Group 1.svg";
import backgroundImg from "../../assets/background-complete.svg";
import Girl1 from "../../assets/girl1.svg";
import Girl2 from "../../assets/girl2.svg";
import Plant from "../../assets/Plant.svg";
import Floor from "../../assets/Floor.svg";
import HeroImage from "../../assets/hero-image.png";

const HeroSection = () => {
  return (
    <section className="hero-section-wrapper">
      <div className="App">
        <div className="hero-section">
          <div className="hero-content">
            <h1 className="hh">
              <span className="hh1">Confidentially</span>
              Grow Your & Your Company's Connections
            </h1>
            <p className="hero-attend">
              While attending conferences & anywhere you cross paths.
            </p>
            <p className="hero-virtual">
              Virtual Intros transforms any public space into a networking
              opportunity. Whether you're at the airport or strolling downtown,
              simply set your location preferences, and you'll be alerted to
              potential connections near by all while keeping your privacy
              intact. Rest assured, names and faces are only revealed if both
              parties choose to opt in.
            </p>
            <p className="hero-download">Download The App</p>
            {/* <div className="hero-buttons">
              <button className="hero-apple-button">
                <img src={appleImage} alt="Apple icon" />
                <div className="button-text">
                  <span>Download on the</span>
                  <span style={{fontSize:"18px"}}>App Store</span>
                </div>
              </button>
              <button className="hero-play-button">
                <img src={playImage} alt="Play icon" />
                <div className="button-text">
                  <span>Get it on</span>
                  <span style={{fontSize:"18px"}}>Play Store</span>
                </div>
              </button>
            </div> */}
            <div className="hero-buttons">
              <img src="https://d3nw6jtllu5stf.cloudfront.net/logo/play_buttons.png" alt="Download Buttons" height="75" />
            </div>
          </div>
          <div className="hero-image-outer">
            <div className="hero-image-thumb">
              <img src={HeroImage} alt="Background-1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
