import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' }); 
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 0); 
  };

  return (
    <footer className="footer-wrapper">
      <div className="App">
        <div className="footer">
          <nav>
            <div className="left-footer">
              <a onClick={() => handleNavigation('/')} style={{ cursor: 'pointer' }}>Home</a>
              <a onClick={() => handleNavigation('/', '#about')} style={{ cursor: 'pointer' }}>About us</a>
              <a onClick={() => handleNavigation('/', '#how-it-works')} style={{ cursor: 'pointer' }}>How it works</a>
              <a onClick={() => handleNavigation('/', '#contact')} style={{ cursor: 'pointer' }}>Contact us</a>
              <a
               href="#" 
              //  target="_blank"
               >Services</a>
            </div>
            <div className="right-footer">
              <a onClick={() => handleNavigation('/termandcondition')} style={{ cursor: 'pointer' }}>Terms & conditions</a>
              <a onClick={() => handleNavigation('/privacy')} style={{ cursor: 'pointer' }}>Privacy Policy</a>
            </div>
          </nav>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright © 2024 Virtual Intros. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
